.button {
  display: flex;
  height: 60px;
  padding: 13px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  width: max-content;
  text-decoration: none;
  background-size: 200% 100%;
  background-image: linear-gradient(145deg, #b013f3, #4b4bff, #2a68d1);
  box-shadow: 1px 1px 8px 0px #2a68d1, -1px -1px 8px 0px #b013f3;
  transition: 0.5s;

  &:hover {
    background-position: 99%;
    box-shadow: 3px 3px 10px 2px #6366ff, -3px -3px 10px 2px #3d40d5;
  }
  &:active {
    transform: scale(0.9);
    box-shadow: 3px 3px 15px 3px #6366ff, -3px -3px 15px 3px #6366ff;
  }

  &__label {
    color: var(--on-surface-a-100);
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family: var(--proxima);
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
  }

  &_icon {
    display: flex;
  }
}
