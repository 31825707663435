@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/typography";

.section {
  @include section-padding;
  background-color: #ffffff;
}

.title {
  @include homeSectionHeading;
  &:global(.MuiTypography-root) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin: 1rem 0;
}

.imageBox {
  width: 198px;
  height: 175px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .imageRating {
    position: absolute;
    bottom: 3px;
    width: 198px;
    height: 175px;
  }
  .photo {
    width: 168px;
    height: 168px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.itemScore {
  display: flex;
  align-items: center;
  gap: 0.37rem;

  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    font-family: var(--proxima);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.itemTitle {
  &:global(.MuiTypography-root) {
    color: #111827;
    text-align: center;
    font-family: var(--proxima);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.placeText {
  &:global(.MuiTypography-root) {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.8rem;
  }
  &.first {
    color: #f89806;
  }
  &.second {
    color: #949ca5;
  }
  &.third {
    color: #d36a28;
  }
}

@include media("sm") {
  .container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .ctaButton {
    width: max-content;
  }
}

@include media("md") {
  .title {
    &:global(.MuiTypography-root) {
      margin-bottom: 1.5rem;
    }
  }
  .items {
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 0;
    .item {
      &:nth-child(1) {
        margin-bottom: 4rem;
      }
      &:nth-child(2) {
        order: -1;
      }
    }
  }
}
