.main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;

  @media (min-width: 62em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.media {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  text-align: center;
  @media (min-width: 62em) {
    text-align: left;
  }
}

.list {
  margin: 0;
  padding: 0 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    color: #fff;
    font-size: clamp(1rem, 0.9167rem + 0.3704vw, 1.25rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
