.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.title {
  color: #fff;
  text-align: center;
  font-family: var(--proxima);
  font-size: clamp(1.5rem, 1.1667rem + 1.4815vw, 2.5rem);
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;
  text-wrap: pretty;
  max-width: 920px;
}
.media {
  max-width: 684px;
  width: 100%;
  position: relative;
}

.player {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  border-radius: 24px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      108deg,
      #9e11df 0%,
      #040532 20.01%,
      #040532 80.02%,
      #2760c5 100.03%
    );
    filter: blur(30.399999618530273px);
    border-radius: 24px;
  }

  .poster {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 24px;
  }

  iframe {
    overflow: hidden;
    border-radius: 24px;
  }

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
