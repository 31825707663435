.title {
  color: #fcf3ff;
  font-family: var(--proxima);
  font-size: clamp(1.5rem, 1.1667rem + 1.4815vw, 2.5rem);
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  text-wrap: pretty;
  text-align: center;
}
