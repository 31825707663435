.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.title {
  text-align: center;
  margin: 0 auto;

  &__highlighted {
    color: #b013f3;
  }
}
