.container {
  position: relative;
  overflow: hidden;
}

.image {
  max-width: 100%;
  height: auto;
  transition: filter 0.3s ease-in-out;
  display: flex;

  &__loading {
    filter: blur(10px);
  }

  &__loaded {
    filter: none;
  }
}
