.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.title {
  text-align: center;
  margin: 0 auto;
  max-width: 920px;
}

.content {
  max-width: 920px;
  width: 100%;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.card {
  max-width: 448px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 24px 24px 24px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__title {
    text-align: center;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    display: flex;
    gap: 8px;
  }

  &__icon {
    display: flex;
  }

  &__text {
    display: block;
    padding-top: 2px;
    color: #fff;
    font-size: clamp(1rem, 0.9167rem + 0.3704vw, 1.25rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
