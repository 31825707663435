.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 48em) {
    gap: 40px;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;

  @media (min-width: 62em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.media {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    display: flex;
    gap: 8px;
  }

  &__icon {
    display: flex;
  }

  &__text {
    display: block;
    padding-top: 2px;
    color: #fff;
    font-size: clamp(1rem, 0.9167rem + 0.3704vw, 1.25rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.title {
  text-align: center;
  @media (min-width: 62em) {
    text-align: left;
  }
}

.footer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (min-width: 48em) {
    padding-top: 24px;
  }
}

.price {
  &__regular {
    &_highlighted {
      color: #ff6969;
    }
  }

  &__current {
    &_highlighted {
      color: #2bad28;
    }
  }
}
