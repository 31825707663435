.wrapper {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.description {
  &__text {
    color: var(--on-surface-a-090);
    text-align: center;
    font-size: clamp(0.875rem, 0.8333rem + 0.1852vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    border-radius: 14px;
    padding: 1px;
    z-index: 1;
    position: relative;

    @keyframes bgr {
      from {
        background: linear-gradient(
          to right,
          rgba(42, 104, 209, 1),
          rgba(42, 104, 209, 0)
        );
      }

      to {
        background: linear-gradient(
          to right,
          rgba(42, 104, 209, 1),
          rgba(42, 104, 209, 1)
        );
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(42, 104, 209, 1),
        rgba(42, 104, 209, 0)
      );
      z-index: -1;
      border-radius: 14px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 1px;
      left: 1px;
      background-color: #180e49;
      z-index: 0;
      border-radius: 14px;
    }

    &:hover {
      &:after {
        background: linear-gradient(
          to right,
          rgba(42, 104, 209, 1),
          rgba(42, 104, 209, 1)
        );
      }
    }

    &:global(.szh-accordion__item--expanded) {
      &:after {
        background: linear-gradient(
          to right,
          rgba(42, 104, 209, 1),
          rgba(42, 104, 209, 1)
        );
      }
      & :global(.szh-accordion__item-heading) {
        transition: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      & :global(.szh-accordion__item-btn) {
        &:after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background-image: url(./icons/minus.svg);
        }
      }
      & :global(.szh-accordion__item-content) {
        margin-top: -16px;
      }
    }
  }

  & :global(.szh-accordion__item-content) {
    width: 100%;
    transition: all 0.3s;
    position: relative;
    z-index: 1;
  }
  & :global(.szh-accordion__item-heading) {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  & :global(.szh-accordion__item-btn) {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    color: #fff;
    font-size: clamp(1.25rem, 1.1667rem + 0.3704vw, 1.5rem);
    font-style: normal;
    font-weight: 700;
    line-height: 24.552px;
    text-align: left;
    padding: 24px;
    font-family: var(--proxima);
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      flex: 0 0 24px;
      height: 24px;
      background-image: url(./icons/plus.svg);
      transition: 0.3s;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  &__text {
    color: #fff;
    font-feature-settings: "ss03" on, "ss07" on;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24.552px;
    text-wrap: pretty;
    max-width: 636px;
    display: inline-block;
    padding: 0 24px 24px;
  }
}

.footer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (min-width: 48em) {
    padding-top: 24px;
  }
}

.price {
  &__regular {
    &_highlighted {
      color: #ff3d2e;
    }
  }

  &__current {
    &_highlighted {
      color: #2bad28;
    }
  }
}

.title {
  text-align: center;
  margin: 0 auto;
}
