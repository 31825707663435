.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media (min-width: 48em) {
    gap: 40px;
  }
}
.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.list {
  list-style: none;
  display: flex;
  gap: 24px;
  margin: 0;
  padding: 0;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex: 0 0 330px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &__title {
    text-align: center;
    margin: auto 0;
    font-size: clamp(1rem, 0.9583rem + 0.1852vw, 1.125rem);
    color: var(--on-surface-a-100);
    font-family: var(--biotif);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  &__image {
    width: 164px;
    aspect-ratio: 1;
  }
}

.footer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (min-width: 48em) {
    padding-top: 24px;
  }
}

.price {
  &__regular {
    &_highlighted {
      color: #ff3d2e;
    }
  }
  &__current {
    &_highlighted {
      color: #2bad28;
    }
  }
}

.title {
  max-width: 720px;
  text-align: center;
  margin: 0 auto;
}
