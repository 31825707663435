.container {
  max-width: 1440px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @media (min-width: 48em) {
    padding: 0 24px;
  }
}
