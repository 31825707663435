.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (min-width: 62em) {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
}

.card {
  max-width: 684px;
  width: 100%;
  flex: 1;
  display: flex;
  padding: 0px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__title {
    text-align: center;
  }

  &__image {
    border-radius: 16px;
    overflow: hidden;
  }

  &__text {
    color: var(--on-surface-a-070);
    font-size: clamp(0.875rem, 0.8333rem + 0.1852vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    text-wrap: pretty;

    &_highlighted {
      color: var(--on-surface-a-100);
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
}

.title {
  text-align: center;
  margin: 0 auto;
}
